import { setToastMessage } from "../redux/slice/Common/toastSlice";
import { setNavigation } from "../redux/slice/NavigationSlice";

export const errorHandler = ({
  err,
  errMessage,
  handleLoader,
  specific,
  dispatch,
}) => {
  const alertError = (message) => {
    dispatch(
      setToastMessage({
        message: specific ? message : errMessage || message,
        type: "error",
      })
    );
  };

  if (err?.status === 403) {
    dispatch(setNavigation("noServicePage"));
  } else if (err?.status === 401) {
    alertError(
      specific ? errMessage : err?.data?.error || "Unauthorized access"
    );
  } else if (err?.status === 400) {
    alertError(specific ? errMessage : err?.data?.error || "Bad request");
    dispatch(setNavigation("homePage"));
  } else if (err?.status === 404) {
    alertError(specific ? errMessage : err?.data?.error || "No area found");
  } else if (err?.status === 503) {
    alertError(
      specific
        ? errMessage
        : err?.data?.error || "Service is temporarily unavailable"
    );
  } else if (err?.status === 429) {
    // console.log('errrrrrr',err)
    alertError(
      specific
        ? errMessage
        : err?.data?.error
    );
  } else {
    alertError("Oops, something went wrong!");
  }

  handleLoader && handleLoader();
};
